import React from "react";
import ImgixFluidImage from "./ImgixFluidImage";
import PlaceHolderImage from "./placeHolderImage";

function CardImage({featureImage, title, subtitle, url, ar, onClick}) {

  const finalImage = featureImage ? <ImgixFluidImage attribution={featureImage.attribution} imageUrl={featureImage.url} sizes="700px" ar={ar||3/2} focalPoint={featureImage.focalPoint} />  : <PlaceHolderImage />;

  const finalElement = <div className="box__inner" style={{borderBottom: "0px solid white", padding: "0"}}>{finalImage}</div>;

  return (
    <li className="box box--secondary swiper-slide">
      {url ? 
        <a href={`${url}`}>{finalElement}</a>
        :
        <a href="#image" onClick={onClick} onKeyDown={onClick}>{finalElement}</a>
      }
    </li>
    
  );
};

export default CardImage;