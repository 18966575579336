import React, { useState } from 'react';
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import SectionHead from "../components/newComponents/sectionHead"
import EditLink from "../components/newComponents/editLink"
import SocialMediaButtons from "../components/newComponents/socialShare"
import CardImage from '../components/newComponents/cardImage';
import ImgixFluidImage from '../components/newComponents/ImgixFluidImage';

export const query = graphql`
  query GalleryEntryQuery($slug: String!) {
    entry: craftGalleriesGalleriesEntry(slug: { eq: $slug }) {
      id
      remoteId
      title
      url
      uri
      displayTitle
      sectionHandle
      featureImage {
        ...AssetFragment
      }
      galleryImages {
        ...AssetFragment
      }

    }

    site: site {
      siteMetadata {
        url
      }
    }
  }
`

const GalleryEntry = ({ data: { entry, site },pageContext }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <Layout pageContext={pageContext}>
      <Seo title={entry.title} image={entry.featureImage[0]?.url} />
      <div className="main">
        <section className="section-inside" style={{ padding: "0" }}>
          <div className="shell">
            <SectionHead
              heading={entry.title}
              image={entry.featureImage[0]}
            />
            
      <div className="boxes swiper-container js-load-more js-slider-boxes-projects">
        <ul>
       
              {entry.galleryImages.map((item, i) => (
                <>
                  <CardImage key={`${item.title}`} ar={1/1} featureImage={item} title={item.title} onClick={() => {
                    setIsOpen(!isOpen);
                    setSelectedItem(i);
                  }}/>
                </>
              ))}
            
        </ul>
        <div className="swiper-pagination boxes__pagination" />
      </div>
      <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? "" : "hidden"}`}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75" /></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>
            <div className="p-4 inline-block align-bottom rounded-lg bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div className="flex justify-end mb-4">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                </div>
              <ImgixFluidImage attribution={entry.galleryImages[selectedItem].attribution} fit="fill" imageUrl={entry.galleryImages[selectedItem].url} sizes="700px" ar={3/2} focalPoint={entry.galleryImages[selectedItem].focalPoint} /> 
            </div>
        </div>
      </div>

                <SocialMediaButtons
                  url={`${site.siteMetadata.url}/${entry.uri}`}
                  title={`Genesis Analytics Project: ${entry.title}`}
                  summary={entry.summary}
                  twitterVia="GenesisEconomic"
                  linkedInSource="Genesis Analytics"
                />

                <EditLink
                  sectionHandle={entry.sectionHandle}
                  remoteId={entry.remoteId}
                />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default GalleryEntry
